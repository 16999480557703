import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { camelize, getCurrentInstance, toHandlerKey } from 'vue'

export type NumericProperties<T extends object> = {
    [K in keyof T]-?: [number] extends [T[K]] ? K : never
}[keyof T]

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function useEmitAsProps<Name extends string>(
    emit: (name: Name, ...args: any[]) => void,
) {
    const vm = getCurrentInstance()

    const events = vm?.type.emits as Name[]
    const result: Record<string, any> = {}
    if (!events?.length) {
        console.warn(
            `No emitted event found. Please check component: ${vm?.type.__name}`,
        )
    }

    events?.forEach((ev) => {
        result[toHandlerKey(camelize(ev))] = (...arg: any) => emit(ev, ...arg)
    })
    return result
}

export function useSyncElScrolls(el1: Ref<HTMLElement | undefined>, el2: Ref<HTMLElement | undefined>) {
    const scroll1 = useScroll(el1)
    const scroll2 = useScroll(el2)

    watch(scroll1.y, (newY) => {
        if (!el2.value)
            return
        el2.value.scrollTop = newY
    }, { immediate: true })

    watch(scroll2.y, (newY) => {
        if (!el1.value)
            return
        el1.value.scrollTop = newY
    }, { immediate: true })
}
