<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { DropdownMenuItem, type DropdownMenuItemProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<DropdownMenuItemProps & { class?: HTMLAttributes['class']; inset?: boolean }>()
</script>

<template>
    <DropdownMenuItem
        v-bind="props"
        :class="cn(
            'relative flex select-none items-center outline-none transition-colors focus:bg-white/12  data-[disabled]:pointer-events-none data-[disabled]:opacity-50 px-16px py-11px rounded-8px gap-12px text-14px leading-18px tracking--0.01em font-semibold cursor-pointer',
            inset && 'pl-8',
            props.class,
        )"
    >
        <slot />
    </DropdownMenuItem>
</template>
